<template>
  <div>
    <v-card>
      <v-row>
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="sheet"
        ></v-skeleton-loader>
        <v-col
          v-else
          md="12"
        >
          <v-sheet
            tile
            height="54"
            class="d-flex mb-5"
          >
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>{{ icons.mdiChevronLeftCircleOutline }}</v-icon>
            </v-btn>
            <h4
              v-if="this.$refs.calendar"
              class="mx-5 ma-2"
              @click="type ='month'"
            >
              {{ $refs.calendar.title }}
            </h4>
            <v-select
              v-model="type"
              :items="types"
              item-text="name"
              item-value="value"
              dense
              outlined
              hide-details
              class="ma-2"
              label="Format"
            ></v-select>
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.next()"
            >
              <v-icon>{{ icons.mdiChevronRightCircleOutline }}</v-icon>
            </v-btn>
          </v-sheet>
          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="cal"
              first-time="08:00"
              interval-count="15"
              interval-width="90"
              :weekdays="weekdays"
              mode="stack"
              locale="fr"
              color="primary"
              :type="type"
              :events="events"
              :event-height="20"
              @click:event="showEvent"
              @click:date="dayView"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card
                color="grey lighten-4"
                min-width="350px"
                flat
              >
                <v-toolbar
                  :color="selectedEvent.color"
                  dark
                >
                  <v-btn icon>
                    <v-icon>{{ icons.mdiAccountBoxOutline }}</v-icon>
                  </v-btn>
                  <!-- eslint-disable vue/no-v-html -->
                  <v-toolbar-title v-html="selectedEvent.studentName"></v-toolbar-title>
                  <!--eslint-enable-->
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <p class="mt-3">
                    Formation : {{ selectedStudent.formation.nom }}
                  </p>
                  <p>
                    Tel : {{ selectedStudent.tel }}
                  </p>
                  <p>
                    En formation
                    <ul>
                      <li>du <b>{{ new Date(selectedStudent.debutFormation) | date('dd MMMM yyyy') }}</b></li>
                      <li>au <b>{{ new Date(selectedStudent.finFormation) | date('dd MMMM yyyy') }}</b></li>
                    </ul>
                  </p>
                  <p>
                    Date de rétractation : <b>{{ new Date(this.$workingDays.getWorkingDaysBefore(selectedStudent.debutFormation, 7)) | date('dd MMMM yyyy') }}</b>
                  </p>
                  <p v-if="selectedStudent.rappel">
                    RAPPEL : <b>{{ new Date(selectedStudent.rappel) | date('dd MMMM yyyy à HH:mm') }}</b>
                  </p>
                </v-card-text>
                <v-card-actions v-if="selectedEvent.category === 'Rappels' && !selectedStudent.rappeled">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="rappelStudent(selectedStudent.id)"
                  >
                    Rappel : OK
                  </v-btn>
                </v-card-actions>
                <v-card-actions v-else-if="selectedStudent.status ==='avantFormation'">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="entreeStudent(selectedStudent.id)"
                  >
                    Valider l'entrée en formation
                  </v-btn>
                </v-card-actions>
                <v-card-actions v-else-if="selectedStudent.status ==='enFormation'">
                  <v-btn
                    outlined
                    block
                    color="success"
                    @click="finFormation(selectedStudent.id)"
                  >
                    Valider la sortie de formation
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="text-center justify-center">
                  <v-btn
                    color="secondary"
                    @click="selectedOpen = false"
                  >
                    Retour
                  </v-btn>
                  <router-link
                    class="ml-5"
                    :to="{ name: 'student-single', params: { id: selectedStudent.id} }"
                  >
                    <v-btn
                      color="primary"
                    >
                      Voir le profil
                    </v-btn>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-btn
        v-for="(event1230, index) in events1230"
        :key="index"
        color="error"
        outlined
        @click="$router.push({ name: 'student-single', params: { id: event1230.value}})"
      >
        {{ event1230.name }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiHelpCircleOutline,
  mdiChevronLeftCircleOutline,
  mdiChevronRightCircleOutline,
  mdiAccountBoxOutline,
} from '@mdi/js'

export default {
  components: {
  },
  data() {
    return {
      loading: true,
      students: [],
      type: 'day',
      types: [
        {
          value: 'month',
          name: 'Mois',
        },
        {
          value: 'week',
          name: 'Semaine',
        },
        {
          value: 'day',
          name: 'Jour',
        },
        {
          value: '4day',
          name: '4 jours',
        },
      ],
      weekdays: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
      ],
      cal: '',
      selectedOpen: false,
      selectedEvent: {},
      selectedStudent: {
        debutFormation: new Date(),
        rappel: new Date(),
        finFormation: new Date(),
        formation: {
          nom: '',
        },
      },
      selectedElement: null,
      finishedKeys: [],
      icons: {
        mdiHelpCircleOutline,
        mdiChevronLeftCircleOutline,
        mdiChevronRightCircleOutline,
        mdiAccountBoxOutline,
      },
    }
  },
  computed: {
    events() {
      const events = []
      this.students.forEach(student => {
        switch (student.status) {
          case 'enFormation':
          case 'inscritCertif':
          case 'certifPassee':
            events.push(
              {
                name: `Sortie de formation : ${student.nom} ${student.prenom}`,
                studentName: `${student.nom} ${student.prenom}`,
                start: new Date(`${student.finFormation}T00:00:00`),
                end: new Date(`${student.finFormation}T23:59:59`),
                category: 'Sorties de formation',
                color: 'success',
                ekey: `finFormation${student.id}`,
                value: student.id,
                timed: false,
              },
            )
            break
          case 'avantFormation':
            events.push(
              {
                name: `Entrée en formation : ${student.nom} ${student.prenom}`,
                studentName: `${student.nom} ${student.prenom}`,
                start: new Date(`${student.debutFormation}T00:00:00`),
                end: new Date(`${student.debutFormation}T23:59:59`),
                category: 'Entrées en formation',
                color: 'primary',
                ekey: `debutFormation${student.id}`,
                value: student.id,
                timed: false,
              },
              {
                name: `Délais de rétractation : ${student.nom} ${student.prenom}`,
                studentName: `${student.nom} ${student.prenom}`,
                start: this.$workingDays.getWorkingDaysBefore(student.debutFormation, 7),
                end: this.$workingDays.getWorkingDaysBefore(student.debutFormation, 7),
                category: 'Délais de rétractation',
                color: 'secondary',
                ekey: `retractation${student.id}`,
                value: student.id,
                timed: false,
              },
              {
                name: `Sortie de formation : ${student.nom} ${student.prenom}`,
                studentName: `${student.nom} ${student.prenom}`,
                start: new Date(`${student.finFormation}T00:00:00`),
                end: new Date(`${student.finFormation}T23:59:59`),
                category: 'Sorties de formation',
                color: 'success',
                ekey: `finFormation${student.id}`,
                value: student.id,
                timed: false,
              },
            )
            break
          case 'sortieFormation':
            break
          default:
            console.log(`Erreur ${student.status}`)
        }
        if (!student.rappeled) {
          let colorTag = 'error'
          if (student.status === 'inscritCertif') {
            colorTag = '#009688'
          } else if (student.status === 'sortieFormation') {
            colorTag = '#01579B'
          } else if (student.status === 'certifPassee') {
            colorTag = '#00695C'
          }
          const daterappel = new Date(student.rappel)
          const timed = !(daterappel.getHours() === 0 && daterappel.getMinutes() === 1)
          events.push(
            {
              name: `RAPPEL : ${student.nom} ${student.prenom}`,
              studentName: `${student.nom} ${student.prenom}`,
              start: new Date(student.rappel),
              end: new Date(new Date(student.rappel).getTime() + 30 * 60000),
              category: 'Rappels',
              color: colorTag,
              ekey: `rappel${student.id}`,
              value: student.id,
              timed,
            },
          )
        }
      })

      return events.filter(e => !this.finishedKeys.includes(e.ekey))
    },
    events1230() {
      return this.events.filter(e => new Date(e.start).getHours() === 12
        && new Date(e.start).getMinutes() === 30
        && this.isSameDay(e.start, this.cal))
    },

  },
  created() {
    this.fetchData()
  },
  methods: {
    isSameDay(someDate, someOtherDate) {
      someDate = new Date(someDate)
      someOtherDate = new Date(someOtherDate)

      return someDate.getDate() === someOtherDate.getDate()
    && someDate.getMonth() === someOtherDate.getMonth()
    && someDate.getFullYear() === someOtherDate.getFullYear()
    },
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/students`).then(
        response => {
          this.students = response.data
          this.loading = false
        },
        error => {
          console.log(error)
        },
      )
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedStudent = this.students.find(student => student.id === event.value)
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    dayView(e) {
      this.type = 'day'
      this.cal = e.date
      console.log(e)
    },
    rappelStudent(id) {
      this.selectedStudent.contacts.push(
        {
          contacter: this.$store.state.user.displayName,
          contactedby: 'phone',
          note: 'Rappelé',
          contactDate: new Date(),
          contactResult: 'suivi',
        },
      )
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${id}`, { rappeled: true, contacts: this.selectedStudent.contacts })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Rappel terminé',
            value: true,
          })
          this.finishedKeys.push(`rappel${id}`)
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la modification du rappel',
            value: true,
          })
          console.log(err)
        })
    },
    entreeStudent(id) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${id}`, { status: 'enFormation' })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Stagiaire entré en formation',
            value: true,
          })
          this.finishedKeys.push(`debutFormation${id}`)
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de l\'entrée en formation',
            value: true,
          })
          console.log(err)
        })
    },
    finFormation(id) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${id}`, { status: 'sortieFormation' })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Stagiaire sorti de formation',
            value: true,
          })
          this.finishedKeys.push(`finFormation${id}`)
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la sortie de formation',
            value: true,
          })
          console.log(err)
        })
    },
  },
}
</script>

<style>
.v-calendar-daily__interval-text,
.v-calendar-weekly__day-label .v-btn__content {
  font-size: 15px !important;
}
.v-calendar-daily_head-weekday,
.v-calendar-weekly__head-weekday
{
  font-size: 20px !important;
}
</style>
